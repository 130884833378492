<template>
    <div class="service-management">
        <div class="service-register-header">
            <span class="title">{{ $t("linked_service_management.title_service_management_register") }}</span>
            <div>
                <span>{{ textDescriptionRegisterOrReRegister }}</span>
                <span>{{ textFullDescriptionRegisterOrReRegister }}</span>
                <span v-if="isLinkedService" class="partner-id">{{
                    $t("linked_service_management.label_partner_company_ID") }} {{ isLinkedService }}</span>
            </div>
        </div>
        <div class="service-body">
            <div class="service-item">
                <div class="input-area">
                    <span>{{ $t("linked_service_management.title_input_partner_company_ID") }}</span>
                    <InputField :inputValue="partnerCompanyId"
                        :label="$t('linked_service_management.placeholder_input_partner_company_ID')"
                        @update:inputValue="handleUpdateValue" />
                </div>

                    <CommonButton
                        class="submit-btn"
                        :label="textBtnRegisterOrReRegister"
                        @action="registerIdPartner"
                        :isDisable="!partnerCompanyId"
                        type="colored"
                        :loading="isLoadingSubmit"
                    />
            </div>
        </div>
        <notification-popup :dialog="dialogNotification" :message="messageNotification" @submit="navigateToList()"
            :typeColor="colorBtn" classes="register"
            :confirmText="$t('linked_service_management.button_confirm_popup_setting')" />

    </div>
</template>

<script>
import { ROUTES } from "@/router/constants";
import { mapActions, mapState } from "vuex";
import CommonButton from '@/components/utils/button.vue';
import InputField from '@/components/products/input/InputField.vue';
import { getDetailsServiceManagement, updateParnerCompanyId } from '@/api/linked-service-management';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';


export default {
    data() {
        return {
            items: [
                {
                    text: this.$t('linked_service_management.hyperlink_home_service_management_setting'),
                    disabled: false,
                    href: this.$route.path.includes(ROUTES.PRODUCTS_EMISSION) ? ROUTES.PRODUCTS_EMISSION + ROUTES.HOME_DASHBOARD : ROUTES.HOME_DASHBOARD
                },
                {
                    text: this.$t('linked_service_management.hyperlink_setting_service_management_setting'),
                    disabled: false,
                    href: this.$route.path.includes(ROUTES.PRODUCTS_EMISSION) ? ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING : ROUTES.SETTING
                },
                {
                    text: this.$t('linked_service_management.hyperlink_text_service_management_setting'),
                    disabled: false,
                    href: this.$route.path.includes(ROUTES.PRODUCTS_EMISSION) ? ROUTES.PRODUCTS_EMISSION + ROUTES.LINKED_SERVICE_MANAGEMENT : ROUTES.LINKED_SERVICE_MANAGEMENT
                },
                {
                    text: this.$t('linked_service_management.hyperlink_text_service_management_register'),
                    disabled: true,
                    href: ROUTES.LINKED_SERVICE_MANAGEMENT_REGISTER
                }

            ],
            partnerCompanyId: null,
            isLinkedService: false,
            messageNotification: '',
            dialogNotification: false,
            colorBtn: 'colored',
            isLoadingSubmit: false,
        }
    },
    components: { CommonButton, InputField, NotificationPopup },
    computed: {
        textBtnRegisterOrReRegister() {
            return this.isLinkedService ? this.$t('linked_service_management.button_service_re_register') : this.$t('linked_service_management.button_service_register')
        },
        textDescriptionRegisterOrReRegister() {
            return this.isLinkedService ? this.$t('linked_service_management.description_service_management_re_register') : this.$t('linked_service_management.description_service_management_register')
        },
        textFullDescriptionRegisterOrReRegister() {
            return this.isLinkedService ? this.$t('linked_service_management.full_description_service_management_re_register') : this.$t('linked_service_management.full_description_service_management_register')
        }
    },

    methods: {
        ...mapActions("commonApp", ["updateBreadCrumb"]),

        handleUpdateValue(value) {
            this.partnerCompanyId = value
        },
        async registerIdPartner() {
            this.isLoadingSubmit = true;
            if (!this.partnerCompanyId) return;
            const payload = {
                partner_company_id: this.partnerCompanyId,
                service_id: this.$route.query?.service_id
            }
            const result = await updateParnerCompanyId(payload);
            this.dialogNotification = true;
            this.isLoadingSubmit = false;
            this.messageNotification = result.message
            if(result.success) {
                this.colorBtn = 'colored'
            } else {
                this.colorBtn = 'redColor'
            }

        },
        navigateToList() {
            this.dialogNotification = false;
            window.location.reload();
        }
    },
    async mounted() {
        this.updateBreadCrumb(this.items);
        const serviceId = this.$route.query?.service_id;
        try {
            const dataService = await getDetailsServiceManagement({ service_id : serviceId });
            this.isLinkedService = dataService?.data?.partner_company_id
        } catch (error) {
            this.$router.push("404");
        }
    }
}
</script>
<style lang="scss" scoped>
.service-management {
    .service-register-header {
        padding: 40px 0 80px 0;
        display: flex;
        flex-direction: column;
        gap: 48px;

        .title {
            color: $goldMid;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            /* 163.636% */
            letter-spacing: 1.1px;
        }

        span {
            color: $monoBlack;

            /* P/Medium/Regular */
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            /* 186.667% */
            letter-spacing: 0.75px;
            display: block;
            word-break: break-all;

            &.partner-id {
                margin-top: 28px;
            }
        }
        @include desktop {
            padding: 40px 40px 80px 40px;
        }
    }

    .service-body {
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .service-item {
            display: flex;
            flex-direction: column;
            gap: 48px;

            .input-area {
                display: flex;
                flex-direction: column;
                gap: 8px;

                span {
                    color: $monoBlack;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    /* 171.429% */
                    letter-spacing: 0.42px;
                }

                ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
                    padding: 0 16px
                }
            }

            button {
                color: $monoWhite;
                text-align: center;
                min-height: 56px !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.42px;
                padding: 14px 24px 18px 24px;
                border-radius: 4px;
                background: $goldMid;
                box-shadow: 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
                &:hover {
                    background: var(--Gold-Light, #A4A184);
                }
                &:active:not(.disabled) {
                    border-radius: 4px;
                    border: 2px solid $blueMid;
                    background: $goldMid;
                    box-shadow: 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
                }
                &.disabled {
                    cursor: default;
                    color: $monoMid;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.42px;
                    border-radius: 4px;
                    background: $monoDusty;
                    box-shadow: 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
                }
            }
        }
    }
}
</style>